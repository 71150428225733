:root {
  --background-color: #f1f3f4;
  --font-color: black;
  --form-box-color: white;
  --radius: 8px;

  --primary: #fcb133;
  --secondary: #5c9ac8;
  --red: #ff595e;
}
html {
  font-size: 16px;
  scroll-behavior: smooth !important;
}
body {
  background-color: var(--background-color);
  font-family: 'Roboto', sans-serif;
  height: 100vh;
}

main {
  width: 100%;
  height: 100%;
  z-index: 10;
}

fieldset {
  opacity: 0.1;
}
fieldset.unlocked {
  opacity: 1 !important;
}

fieldset:disabled {
  opacity: 0.4;
}

.disabled-pictures-section {
  opacity: 0.4;
}

.static-cursor:hover {
  cursor: auto !important;
}

#pic-loading {
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}
#email-loading {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  overflow-y: hidden;
}

#windows {
  padding-bottom: 7rem !important;
}

#newWindow.read {
  background-color: #e3e3e3 !important;
  color: grey;
}

#part2shadow {
  width: 100%;
  height: 680px;
  position: absolute;
  top: 350px;
  left: 0;
  background-color: rgba(241, 243, 244, 0.75);
  z-index: 1000000;
}

.wrapper{
  width: 100%;
  height: 100vh;
  display: grid;
  place-items: center;
}

.help-button {
  background-color: var(--secondary);
  border-radius: 100%;
  padding: 0.07rem 0.35rem;
  font-size: 0.7rem;
  color: white;
}

.edit-link-on-tile {
  position: absolute;
  bottom: -4px;
  right: 0;
}

.img-logo {
  width: 65%;
}

.read .edit-link-on-tile {
  color: white !important;
}

h1 {
  font-size: 24px;
  color: #737373;
  font-weight: 500;
}
h2 {
  font-size: 2.5rem;
}
h3 {
  font-size: 18px;
  color: #565656;
  border-bottom: 1px solid #737373;
  margin-bottom: 13px;
}
p {
  margin-bottom: 0;
  padding-bottom: 0;
}

.form-check input[type='radio']:checked + label {
  font-weight: bold;
  color: var(--secondary);
}

form label {
  color: #565656;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 500;
}
form input[type='text'],
form input[type='email'],
form input[type='phone'] {
  border-radius: 0px;
}
form input[type='text']:focus,
form input[type='email']:focus,
form input[type='phone']:focus {
  border-color: var(--secondary);
  box-shadow: 0 0 0 0.2rem rgba(92, 154, 200, 0.25);
}

form button[type='submit'],
.btn.btn-secondary {
  width: 100%;
  border-radius: 0;
  background-color: var(--secondary);
  border-color: #5c9ac8;
  padding-top: 10px;
  padding-bottom: 10px;
}
form button[type='submit']:hover,
.btn.btn-secondary:hover {
  background-color: #4a7ba0;
  border-color: #4a7ba0;
}
form button[type='submit']:active,
form button[type='submit']:focus,
.btn.btn-secondary:active,
.btn.btn-secondary:focus {
  background-color: #4a7ba0 !important;
  box-shadow: 0 0 0 0.2rem rgba(92, 154, 200, 0.25);
}

.btn.btn-primary {
  width: 100%;
  border-radius: 0;
  background-color: var(--primary);
  border-color: var(--primary);
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn.btn-primary:hover {
  background-color: #eba52f;
  border-color: #eba52f;
}
.btn.btn-primary:active,
.btn.btn-primary:focus {
  background-color: #eba52f !important;
  border-color: #eba52f !important;
  box-shadow: 0 0 0 0.2rem rgba(235, 165, 47, 0.25) !important;
}
.btn:disabled {
  opacity: 25%;
}
button[name='remove'] {
  width: 100%;
  border-radius: 0;
  color: white;
  background-color: var(--red) !important;
  border-color: #d1494d !important;
  padding-top: 10px;
  padding-bottom: 10px;
}
button[name='remove']:hover {
  background-color: #d1494d !important;
  border-color: #d1494d !important;
  color: white;
}
button[name='remove']:active,
button[name='remove']:focus {
  background-color: #d1494d !important;
  box-shadow: 0 0 0 0.2rem rgba(92, 154, 200, 0.25) !important;
  color: white !important;
}

/**
* Form Boxes
*/
.form-box {
  background-color: var(--form-box-color);
  color: var(--font-color);
}

.background-primary {
  background-color: var(--primary);
  color: white;
  padding: 10%;
}
.background-windows {
  background-color: #efefef;
}

.field-filled {
  border-color: var(--secondary);
  border-width: 2px;
}

@media (max-width: 768px) {
  .form-box {
    margin: 0 auto;
  }
}

/**
* Window Box
*/
.window-box {
  transition: all 0.2s;
}
.window-box:hover {
  cursor: pointer;
  background-color: #ecf0f1 !important;
}

.text-light-grey {
  color: lightgray;
}
.read {
  background-color: var(--primary) !important;
  color: white;
}
.read:hover {
  background-color: #d08b16 !important;
}
.read .text-light-grey {
  color: #ffd58e;
}
.send-box {
  background-color: var(--primary);
  color: white;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s;
  position: fixed;
  bottom: 0;
  margin-left: -3rem;
  width: 100%;
}

@media (min-width: 768px) {
  .send-box {
    width: 360px;
  }
}
@media (min-width: 992px) {
  .send-box {
    width: 480px;
  }
}

@media (min-width: 1200px) {
  .send-box {
    width: 570px;
  }
}

/**
* Allerts
*/

.Toastify__toast--warning {
  background-color: var(--primary) !important;
}
.Toastify__toast {
  padding: 16px !important;
}

/**
* Data Pond
*/
.pictures-section {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.btn-picture-uploader {
  width: 100px;
  height: 75px;
  padding: 0.25rem;
  border: 1px dashed black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.btn-picture-uploader:hover {
  background-color: #ecf0f1;
  cursor: pointer;
}

.btn-picture-uploader .plus-sign {
  font-size: 2rem;
  margin-bottom: -2rem;
}
.btn-picture-uploader span {
  font-size: 0.9em;
}

.picture-display {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;
}

.picture-display:hover .remove {
  visibility: visible;
}
.picture-display img {
  height: 75px;
  border: 1px solid black;
  margin-top: -24px;
}

.image-display{
  object-fit: cover;
  max-width: 100%;
}

.picture-display .remove {
  background-color: #ff595e;
  border-radius: 100px;
  color: white;
  width: 24px;
  display: flex;
  justify-content: center;
  position: relative;
  top: -12px;
  right: -12px;
  cursor: pointer;
  visibility: hidden;
}

/*
.btn-picture-uploader {
  background-color: white;
  border: 1px dashed black;
  transition: all .2s;
  border-radius: 0px;
  height: 75px;
  width: 75px;
  display: inline-block;
  margin-top: -24px;
}
.btn-picture-uploader:hover {
  background-color: #ECF0F1
}


.form-part-1-picture-display {
  display: inline-block;
  border: 1px solid black;
  margin-left: .5rem;
}
.form-part-1-picture-display img {
  height: 75px;
  display: inline-block;
  margin-top: -24px;
} */
