:root {
  --background-color: #f1f3f4;
  --font-color: black;
  --form-box-color: white;
  --radius: 8px;

  --primary: #FCB133;
  --secondary: #5C9AC8;
  --red: #FF595E;
}
html {
  font-size: 16px;
  scroll-behavior: smooth !important;
}
body {
  background-color: var(--background-color);
  font-family: 'Roboto', sans-serif;
  height: 100vh;
}
